import React, { Fragment, lazy, Suspense } from 'react';
import {
  Route,
  Switch,
  BrowserRouter
} from 'react-router-dom';
// import { apiEndpoint } from './prismic-configuration';
import TagManager from 'react-gtm-module'
import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';

const tagManagerArgs = {
  gtmId: 'GTM-PSXRBJC'
}
TagManager.initialize(tagManagerArgs)

const Home = lazy(() => import('./pages/Home'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Page = lazy(() => import('./pages/Page'));
const Projects = lazy(() => import('./pages/Projects'));
const Preview = lazy(() => import('./pages/Preview'));
const BlogHome = lazy(() => import('./pages/Blog/BlogHome'));
const PostBlog = lazy(() => import('./pages/Blog/PostBlog'));
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const TAC = lazy(() => import('./pages/TermsAndPolicy/TAC'));
const Policy = lazy(() => import('./pages/TermsAndPolicy/Policy'));

const App = () => {
  // const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  // const repoName = repoNameArray[1];

  return (
    <Fragment>
      <Suspense fallback={<div>Cargando...</div>}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/preview' component={Preview} />
            <Route exact path='/proyectos/:uid' component={Page} />
            <Route exact path='/blog' component={BlogHome} />
            <Route exact path='/proyectos' component={Projects} />
            <Route exact path='/nosotros' component={AboutUs} />
            <Route exact path='/contacto' component={Contact} />
            <Route exact path='/terminos-y-condiciones' component={TAC} />
            <Route exact path='/politica-de-privacidad' component={Policy} />
            <Route exact path='/blog/:uid' component={PostBlog} />
            <Route component={NotFound} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </Fragment>
  );
};

export default App;
